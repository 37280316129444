import React from "react";

import {
  emailBlue,
  emailWhite,
  phoneBlue,
  phonewhite,
} from "../../assets/images.js";

const Contacts = () => {
  return (
    <>
      <div className="contacts">
        <div className="contact-section">
          <div className="contact-section__email">
            <a href="mailto:info@firsthelp.com">
              <img
                className="contact-section__email__icon not500"
                src={emailBlue}
                alt="email"
              />
              <img
                className="contact-section__email__icon W500"
                src={emailWhite}
                alt="email"
              />
              <p className="contact-text"> info@firsthelp.com</p>
            </a>
          </div>
          <div className="contact-section__phone">
            <a href=" tel:(866) 343-4345">
              <img
                className="contact-section__phone__icon not500"
                src={phoneBlue}
                alt="phone"
              />
              <img
                className="contact-section__phone__icon W500"
                src={phonewhite}
                alt="phone"
              />
              <p className="contact-text"> (866) 343-4345 </p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contacts;
