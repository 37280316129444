import "./i18n";
import React, { Suspense } from "react";
import "./assets/sass/style.scss";
import AppRouter from "./routes";

const App = () => {
  return (
    <>
      <Suspense fallback={null}>
        <AppRouter />
      </Suspense>
    </>
  );
};

export default App;
