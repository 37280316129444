import React, { useState } from "react";

import Payments from "./payments";
// import Languages from "./languages";
import Navigation from "./navigation";
import HeaderForMobile from "./headerForMobile";
import { emailTop, phone, certificate, logoFull } from "../images";

const Header = () => {
  const [showPayment, setShowPayment] = useState(false);
  const [activeMenu, setActiveMenu] = useState(window.location.pathname);

  return (
    <>
      <div className="header-below-500px">
        <HeaderForMobile setShowPayment={setShowPayment} />
      </div>
      <div className="header-above-500px">
        <div className="header-wrapper">
          <div className="header">
            <div className="header-contains">
              <div className="header-contains__top-right">
                <div className="header-contains__top-right__contacts">
                  <div className="header-contains__top-right__contacts__email">
                    <a href="mailto:info@firsthelp.com">
                      <img
                        className="header-contains__top-right__contacts__email__icon"
                        src={emailTop}
                        alt="email"
                      />
                      customerservice@firsthelp.com
                    </a>
                  </div>
                  <div className="header-contains__top-right__contacts__phone">
                    <a href="tel:(866) 343-4345">
                      <img
                        className="header-contains__top-right__contacts__phone__icon"
                        src={phone}
                        alt="phone"
                      />{" "}
                      (866) 343-4345{" "}
                    </a>
                  </div>
                </div>
                <div className="header-contains__top-right__languages">
                  {/* <Languages />{" "} */}
                </div>
              </div>

              <div className="header-contains__left">
                <div className="header-contains__left__logo">
                  <img src={logoFull} alt="logo"></img>
                </div>
              </div>

              <div className="navigation-container">
                <Navigation
                  setShowPayment={setShowPayment}
                  activeMenu={activeMenu}
                  setActiveMenu={setActiveMenu}
                />
              </div>
            </div>
            <div className="certificate">
              <a
                href="https://www.greatplacetowork.com/certified-company/7022090"
                target="_blank"
                rel="noreferrer"
              >
                <img className="" src={certificate} alt="certificate"></img>
              </a>
            </div>
          </div>
        </div>

        {showPayment ? (
          <Payments
            showPayment={showPayment}
            setShowPayment={setShowPayment}
            setActiveMenu={setActiveMenu}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Header;
