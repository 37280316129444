import React from "react";

import Info from "./info";
import Footer from "./footer";
import Header from "./header";
import Contacts from "./contacts";

const Home = () => {
  return (
    <div className="homepage">
      <Header />
      <Info />
      <Contacts />
      <Footer />
    </div>
  );
};

export default Home;
