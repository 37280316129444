import React from "react";

import Header from "./header";
import Footer from "./footer";
import Contacts from "./contacts";
import SignUpTodayBtn from "./utils/signUpTodayBtn";
import {
  approved,
  funding,
  innovative,
  integration,
  language,
  signup,
  training,
} from "../images";

const Dealers = () => {
  return (
    <>
      <div className="wrapper">
        <Header />
        <div className="dealers-container">
          <div className="dealers-container__one">
            <SignUpTodayBtn />
          </div>
          <div className="dealers-container__two">
            <div className="dealers-container__two__text">
              <h1 className="text-big">How Can I Sign Up?</h1>
              <h2 className="text-mid">Talk to one of our Sales Associates</h2>
              <p className="text-small">
                Please contact us at <a href="tel:781-474-5117">781-474-5117</a>{" "}
                so one of our dealer relationship experts can guide you through
                the on-boarding process.
              </p>
            </div>
            <div className="dealers-container__two__image">
              <img src={signup} className="access-image" alt="signup"></img>
            </div>
          </div>
          <div className="dealers-container__three">
            <div className="dealers-container__three__content">
              <img
                src={innovative}
                className="circle-image"
                alt="innovative"
              ></img>
              <p className="text-small">Innovative Underwriting </p>
            </div>
            <div className="dealers-container__three__content">
              <img src={funding} className="circle-image" alt="funding"></img>
              <p className="text-small">Fast Funding</p>
            </div>
            <div className="dealers-container__three__content">
              <img src={training} className="circle-image" alt="training"></img>
              <p className="text-small">Hands on Training</p>
            </div>
            <div className="dealers-container__three__content">
              <img
                src={integration}
                className="circle-image"
                alt="integration"
              ></img>
              <p className="text-small">Easy Integration</p>
            </div>
          </div>
          <div className="dealers-container__four">
            {" "}
            <div className="dealers-container__four__image">
              <img src={approved} className="access-image" alt="apply"></img>
            </div>
            <div className="dealers-container__four__text">
              <h1 className="text-big">How do Customers Apply?</h1>
              <h2 className="text-mid">Help Educate your Clients</h2>
              <p className="text-small">
                First Help Financial is an indirect auto dealer. This means we
                do not take applications directly from consumers. We do however
                have a large network of wonderful dealer partners we can put you
                in touch with.
              </p>
            </div>
          </div>
          <div className="dealers-container__five">
            {" "}
            <div className="dealers-container__five__text">
              <h1 className="text-big">Languages We Support</h1>
              <h2 className="text-mid">Multi-Lingual Customer Service</h2>
              <p className="text-small">
                We have friendly and courteous reps fluent in English, Brazilian
                Portuguese, Cape Verdean Portuguese and Spanish ready to help
                you with any questions you may have.
              </p>
            </div>
            <div className="dealers-container__five__image">
              <img src={language} className="access-image" alt="language"></img>
            </div>
          </div>
        </div>
        <Contacts />
        <Footer />
      </div>
    </>
  );
};

export default Dealers;
