import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// import Languages from "./languages";
import Navigation from "./navigation";
import { certificateSmall, logoSmall } from "../images";

const HeaderForMobile = ({ setShowPayment }) => {
  const [menuMobile, setMenuMobile] = useState(false);
  const [isHamburgerChecked, setHamburgerChecked] = useState(false);
  let navigate = useNavigate();
  return (
    <>
      <div className="header-mobile">
        <div className="hamburger-menu">
          <label className="container">
            <input
              type="checkbox"
              checked={isHamburgerChecked}
              onClick={() => {
                setMenuMobile(!menuMobile);
                setHamburgerChecked(!isHamburgerChecked);
              }}
            />
            <div className="checkmark">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </label>
        </div>
        <div>
          {" "}
          <img src={logoSmall} alt="FHF-logo"></img>
        </div>
        <div>
          <img
            className="certificate-small"
            src={certificateSmall}
            alt="certificate"
          ></img>
        </div>
      </div>

      {menuMobile ? (
        <>
          <div className="navigation-mobile">
            <Navigation
              setHamburgerChecked={setHamburgerChecked}
              setMenuMobile={setMenuMobile}
              setShowPayment={setShowPayment}
            />

            <div className="language-mobile">{/* <Languages /> */}</div>

            <div className="footer-mobile">
              <div className="company-address">
                <a href="tel:(866) 343-4345">(866) 343-4345 </a>
                <br />
                <p>
                  160 Gould St. Suite 100, <br />
                  Needham, MA 02494
                </p>
              </div>
              <div className="copyright">
                <p>Copyright 2020 First Help Financial</p>
                <p>NMLS Number: 979663</p>
                <a
                  href="https://www.nmlsconsumeraccess.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  NMLS Consumer Access Site
                </a>
              </div>
              <div>
                <button
                  onClick={() => {
                    navigate("/terms-of-service");
                  }}
                >
                  Terms of Service
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    navigate("/privacy-policy");
                  }}
                >
                  Privacy Policy
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default HeaderForMobile;
