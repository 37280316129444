import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Home from "./assets/components/home.js";
import Dealers from "./assets/components/dealers.js";
import Customers from "./assets/components/customers.js";
import PrivacyPolicy from "./assets/components/privacyPolicy.js";
import TermsOfService from "./assets/components/termsOfService.js";
import Investor from "./assets/components/investorRelation/investor.js";
import PaymentsForMobile from "./assets/components/paymentsForMobile.js";
import PrivacyNotice from "./assets/components/privacyNotice.js";
import SocialBondMetrics2022 from "./assets/components/investorRelation/socialBondMetrics2022.js";
import SocialBondMetrics2023 from "./assets/components/investorRelation/socialBondMetrics2023.js";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Navigate to="/" />} />
        <Route path="" element={<Home />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/dealers" element={<Dealers />} />
        <Route path="/investor-relations" element={<Investor />} />
        <Route path="/payments" element={<PaymentsForMobile />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/privacy-notice" element={<PrivacyNotice />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route
          path="/social-bond-metrics-2022"
          element={<SocialBondMetrics2022 />}
        />
        <Route
          path="/social-bond-metrics-2023"
          element={<SocialBondMetrics2023 />}
        />
      </Routes>
    </BrowserRouter>
  );
}
export default AppRouter;
