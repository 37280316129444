import React from "react";

import Footer from "./footer";
import Header from "./header";
import Contacts from "./contacts";
import PayYourBill from "./utils/payYourBill";
import {
  customer,
  cycle,
  pay,
  report,
  review,
  service2,
  springFourLogo,
  welcome,
} from "../images";

const Customers = () => {
  return (
    <>
      <div className="customers">
        <Header />
        <div className="customers-container">
          <div className="customers-container__one">
            <PayYourBill />
          </div>
          <div className="customers-container__two">
            <div className="customers-container__two__text">
              <h1 className="text-big">Why Should You Choose Us?</h1>
              <h2 className="text-mid">Exceptional Customer Service</h2>
              <p className="text-small">
                We are deeply invested in providing you the customer service you
                deserve. From application to payoff, our multi-lingual
                representatives will work with you to help you understand your
                loan and resolve any issues you may have.
              </p>
            </div>
            <div className="customers-container__two__image">
              <img src={customer} className="access-image" alt="access"></img>
            </div>
          </div>
          <div className="customers-container__three">
            <div className="customers-container__three__content">
              <img src={service2} className="circle-image" alt="service"></img>
              <p className="text-small">Multi-Lingual Customer Service</p>
            </div>
            <div className="customers-container__three__content">
              <img src={cycle} className="circle-image" alt="cycle"></img>
              <p className="text-small">Full Loan Life Cycle Support</p>
            </div>
            <div className="customers-container__three__content">
              <img src={review} className="circle-image" alt="review"></img>
              <p className="text-small">Personalized Final Review </p>
            </div>
            <div className="customers-container__three__content">
              <img src={welcome} className="circle-image" alt="welcome"></img>
              <p className="text-small">Comprehensive Welcome Package </p>
            </div>
            <div className="customers-container__three__content">
              <img src={pay} className="circle-image" alt="pay"></img>
              <p className="text-small">Multiple Ways to Pay</p>
            </div>
            <div className="customers-container__three__content">
              <img src={report} className="circle-image" alt="report"></img>
              <p className="text-small">Tri-Bureau Credit Reporting</p>
            </div>
          </div>
          <div className="customers-container__four">
            <div className="customers-container__four__image">
              <img
                onclick
                src={springFourLogo}
                className="access-image"
                alt="access"
                onClick={() => {
                  window.open(
                    "https://springfourdirect.com/firsthelpfinancial/",
                    "_blank"
                  );
                }}
              ></img>
            </div>
            <div className="customers-container__four__text">
              <p className="text-small">
                Through First Help Financial's Partnership with SpringFour, you
                can find local resources to save money on things like groceries,
                utility costs, and prescription medications. SpringFour can even
                connect you to employment resources or affordable childcare
                options.
              </p>
            </div>
          </div>
        </div>
        <Contacts />
        <Footer />
      </div>
    </>
  );
};

export default Customers;
