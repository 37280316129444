import React from "react";

import {
  access,
  career,
  community,
  mission,
  service,
  support,
  team,
  values,
} from "../images";
import PayYourBill from "./utils/payYourBill";

const Info = () => {
  return (
    <>
      <div className="info-container">
        <div className="info-container__one">
          <PayYourBill />
        </div>
        <div className="info-container__two">
          <div className="info-container__two__text">
            <h1 className="text-big">Get Access</h1>
            <h2 className="text-mid">Learn About Our Program</h2>
            <p className="text-small">
              First Help Financial is an innovative and fast-growing auto
              finance company that has served customers with limited access to
              financial services for over a decade. Our mission is to provide
              fairly priced auto loans to consumers who are usually overlooked
              or mispriced by traditional financial institutions.
            </p>
          </div>
          <div className="info-container__two__image">
            <img src={access} className="access-image" alt="access"></img>
          </div>
        </div>
        <div className="info-container__three">
          <div className="info-container__three__content">
            <img src={mission} className="circle-image" alt="mission"></img>
            <p className="text-small">Mission Driven</p>
          </div>
          <div className="info-container__three__content">
            <img src={values} className="circle-image" alt="values"></img>
            <p className="text-small">Values Driven</p>
          </div>
          <div className="info-container__three__content">
            <img src={community} className="circle-image" alt="community"></img>
            <p className="text-small">Community Minded</p>
          </div>
          <div className="info-container__three__content">
            <img src={team} className="circle-image" alt="team"></img>
            <p className="text-small">Team Oriented</p>
          </div>
          <div className="info-container__three__content">
            <img src={support} className="circle-image" alt="support"></img>
            <p className="text-small">Support Driven</p>
          </div>
        </div>
        <div className="info-container__four">
          <div className="info-container__four__image">
            <img src={career} className="access-image" alt="access"></img>
          </div>
          <div className="info-container__four__text">
            <h1 className="text-big ">Looking for a Career?</h1>
            <h2 className="text-mid">Learn More About your Future</h2>
            <p className="text-small">
              The First Help Financial family has dedicated service to our
              communities for over a decade. We value culture, diversity,
              collaboration, and work-life harmony. Enjoy a journey with us as
              we invest in your career.
            </p>
          </div>
        </div>
        <div className="info-container__five">
          <div className="info-container__five__text">
            <h1 className="text-big">Impeccable Service</h1>
            <h2 className="text-mid">We are Here to Help</h2>
            <p className="text-small">
              When you have a loan with First Help Financial, you have a partner
              for your financial success. We are deeply invested in providing
              you the customer service you deserve. From application to payoff,
              our multi-lingual representatives will work with you to help you
              understand your loan and resolve any issues you may have.
            </p>
          </div>
          <div className="info-container__five__image">
            <img src={service} className="access-image" alt="access"></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info;
