import React from "react";
import { useNavigate } from "react-router-dom";

import { glassdoor, linkedn } from "../images";

const Footer = () => {
  let navigate = useNavigate();
  return (
    <>
      <footer>
        <div className="footer-section">
          <div className="socials">
            <a
              href="https://www.linkedin.com/company/first-help-financial-llc-/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedn} className="linkedn" alt="linkedn"></img>
            </a>
            <a
              href="https://www.glassdoor.com/Overview/Working-at-First-Help-Financial-EI_IE1256488.11,31.htm"
              target="_blank"
              rel="noreferrer"
            >
              <img src={glassdoor} alt="glassdoor"></img>
            </a>
          </div>
          <div className="company-address">
            <a href="tel:(866) 343-4345">(866) 343-4345 </a>
            <br />
            <p>
              160 Gould St. Suite 100, <br />
              Needham, MA 02494
            </p>
          </div>
          <div className="copyright">
            <p>Copyright 2024 First Help Financial</p>
            <p>NMLS Number: 979663</p>
            <a
              href="https://www.nmlsconsumeraccess.org/"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              NMLS Consumer Access Site
            </a>
          </div>
          <div>
            <button
              onClick={() => {
                navigate("/terms-of-service");
              }}
            >
              Terms of Service
            </button>
          </div>
          <div>
            <button
              onClick={() => {
                navigate("/privacy-policy");
              }}
            >
              Privacy Policy
            </button>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
