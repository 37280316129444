import React from "react";
import { useTranslation } from "react-i18next";

const PayYourBill = () => {
  const { t } = useTranslation();
  return (
    <>
      <button
        onClick={() => {
          window.open("https://www.myfirsthelploan.com/login", "_blank");
        }}
        className="pay-btn hover-button"
      >
        {t("pay your bill")}
      </button>
    </>
  );
};

export default PayYourBill;
