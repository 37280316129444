import React from "react";
import { useTranslation } from "react-i18next";

const SignUpTodayBtn = () => {
  const { t } = useTranslation();
  return (
    <>
      <button
        onClick={() => {
          window.open(
            "https://deals.firsthelpfinancial.com/user/user/login/",
            "_blank"
          );
        }}
        className="sign-up-today-btn hover-button"
      >
        {t("sign up today")}
      </button>
    </>
  );
};

export default SignUpTodayBtn;
