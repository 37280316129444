import React from "react";
import { Trans, useTranslation } from "react-i18next";

import Header from "./header";
import Footer from "./footer";
import Contacts from "./contacts";
import { appleStore, googleStore } from "../images";

const PaymentsForMobile = ({ showPayment, setShowPayment, setActiveMenu }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="payments-mobile">
        <Header />
        <div className="payments-mobile__container">
          <div className="payments-mobile__container__header">
            <h1 className="text-big">Pay Your Bill The Way You Want To</h1>
            <h2 className="text-mid">Flexible Payment Options</h2>
            <p className="text-small">
              Pay anytime, anywhere in any way you wish. Even if you are always
              on the go, paying your bill at First Help Financial is easy as
              ever. Enjoy our hassle-free payment options including automatic
              payments, online payments or even cash options at thousands of our
              nationwide locations.
            </p>
          </div>
          <div className="payments-mobile__container__content easy-pay">
            <h1> {t("payments.FHF Easy Pay.self")}</h1>
            <h2>
              {" "}
              <Trans
                i18nKey="payments.FHF Easy Pay.content.left"
                components={{
                  b: <strong className="bold-blue"></strong>,
                }}
              ></Trans>
            </h2>
            <div className="easy-pay__content">
              <p>
                The easiest way to pay and stay current on your loan! Payments
                will be automatically withdrawn from your bank account,
                requiring no action from you to stay current, truly hassle-free.
              </p>
            </div>
          </div>
          <div className="payments-mobile__container__content debit-card">
            <h1>Debit Card or Bank Account Via Our App</h1>
            <h2>
              Call one of our customer service specialists today to make a
              payment by phone.
            </h2>
            <div className="debit-card__content">
              <p>
                Make payments from your phone or computer. To link your loan and
                manage your account you will need your date of birth, zip code
                and last 6 digits of your FHF account number.
              </p>
              <div className="downlaod-buttons">
                <a
                  href="https://itunes.apple.com/us/app/first-help-financial/id1348601768"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>
                    <img src={appleStore} alt="apple store"></img>
                  </button>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.appster.payix.firsthelp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button>
                    <img src={googleStore} alt="google store"></img>
                  </button>
                </a>
              </div>
              <div className="pay-now-button">
                <button
                  className="hover-button"
                  onClick={() => {
                    window.open(
                      "https://www.myfirsthelploan.com/login",
                      "_blank"
                    );
                  }}
                >
                  Pay Now
                </button>
              </div>
            </div>
          </div>
          {/* Check or Money Order by Phone */}
          <div className="payments-mobile__container__content money-by-phone">
            <h1>Check or Money Order by Phone</h1>
            <h2>
              Call one of our customer service specialists today to make a
              payment by phone.
            </h2>
            <div className="money-by-phone__content">
              <p>
                Please call us at (866) 343 4345 when ready to make your
                payment.
              </p>
            </div>
          </div>
          {/* Check or Money by Mail */}
          <div className="payments-mobile__container__content money-by-mail">
            <h1>Check or Money by Mail</h1>
            <h2>You can use USPS or FedEx to deliver your payment</h2>
            <div className="money-by-mail__content">
              <p>
                <Trans
                  i18nKey="payments.Check or Money by Mail.content.right"
                  components={{
                    p: <strong className="alert"></strong>,
                  }}
                ></Trans>
              </p>
              <br />
              <div className="addresses">
                <div>
                  <h3>USPS - Post Office</h3>
                  <p className="address-text">
                    First Help Financial P.O. Box 95517 Chicago, IL 60694-5517
                  </p>

                  <p>
                    <em>
                      * NOTE: The above address must not be used for packages
                      sent via courier (FedEx/UPS). For this, please use the
                      following address:
                    </em>
                  </p>
                </div>
                <div>
                  <h3>Fed-Ex/UPS </h3>
                  <p className="address-text">
                    Conduent c/o BMO Harris\\\\ LBX 95517 141 W Jackson
                    Blvd/Suite 1000 Chicago, IL 60604
                  </p>

                  <p>
                    <em>
                      Please be sure to mail your payment at least 5 days in
                      advance of your due date so it arrives by your due date.
                    </em>
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* Pay Cash With MoneyGram */}
          <div className="payments-mobile__container__content moneygram">
            <h1>{t("payments.Pay Cash With MoneyGram.self")}</h1>
            <h2>{t("payments.Pay Cash With MoneyGram.content.left")}</h2>
            <div className="moneygram__content">
              <p>
                <Trans
                  i18nKey="payments.Pay Cash With MoneyGram.content.right"
                  components={{
                    a: (
                      <a
                        href="https://www.moneygram.com/mgo/us/en/locations"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {""}
                      </a>
                    ),
                  }}
                ></Trans>
              </p>
            </div>
          </div>
          {/* Pay Cash at 7-Eleven and CVS Stores */}
          <div className="payments-mobile__container__content csv">
            <h1> {t("payments.Pay Cash at 7-Eleven and CVS Stores.self")}</h1>
            <h2>
              {t("payments.Pay Cash at 7-Eleven and CVS Stores.content.left")}
            </h2>
            <div className="csv__content">
              <p>
                {t(
                  "payments.Pay Cash at 7-Eleven and CVS Stores.content.right"
                )}
              </p>
            </div>
          </div>
        </div>
        <Contacts />
        <Footer />
      </div>
    </>
  );
};

export default PaymentsForMobile;
