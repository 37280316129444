import React from "react";
import { useNavigate } from "react-router-dom";

import { logoFull } from "../images";

const PrivacyNotice = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className="privacy-notice-page">
        <div className="privacy-notice">
          <div className="company-image">
            <img
              onClick={() => {
                navigate("/");
              }}
              src={logoFull}
              alt="First Help Financial"
            ></img>
          </div>
          <div className="privacy-policy__content">
            <div className="privacy-policy__content__text">
              <div className="page-wrap">
                <div className="page-head"></div>
                <div className="fact clearfix">
                  <div className="fact_text">FACTS</div>
                  <div className="fact_desp">
                    <p className=" font-big">
                      WHAT DOES FIRST HELP FINANCIAL DO WITH YOUR PERSONAL
                      INFORMATION?
                    </p>
                  </div>
                </div>
                <div className="pp-wrap clearfix">
                  <div className="fact_block">
                    <div className="fact_block_left">Why?</div>
                    <div className="fact_block_right">
                      <p>
                        Financial companies choose how they share your personal
                        information. Federal law gives consumers the right to
                        limit some but not all sharing. Federal law also
                        requires us to tell you how we collect, share, and
                        protect your personal information. Please read this
                        notice carefully to understand what we do.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pp-wrap clearfix">
                  <div className="fact_block">
                    <div className="fact_block_left">What?</div>
                    <div className="fact_block_right">
                      <p>
                        The types of personal information we collect and share
                        depend on the product or service you have with us. This
                        information can include:
                      </p>
                      <div style={{ paddingLeft: "30px" }}>
                        <ul style={{ margin: "0px", padding: "0px" }}>
                          <li>Social Security number and income</li>
                          <li>Account balances and payment history</li>
                          <li>Credit history and credit scores</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pp-wrap clearfix">
                  <div className="fact_block">
                    <div className="fact_block_left">How?</div>
                    <div className="fact_block_right">
                      <p>
                        All financial companies need to share customers'
                        personal information to run their everyday business. In
                        the section below, we list the reasons financial
                        companies can share their customers' personal
                        information; the reasons First Help Financial chooses to
                        share; and whether you can limit this sharing.
                      </p>
                    </div>
                  </div>
                </div>
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="details_head" width="50%">
                        Reasons we can share your personal information
                      </td>
                      <td className="details_head align_center">
                        Does First Help Financial share?
                      </td>
                      <td className="details_head align_center">
                        Can you limit this sharing?
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <h4>For our everyday business purposes -</h4>
                        <p>
                          such as to process your transactions, maintain your
                          account(s), respond to court orders and legal
                          investigations, or report to credit bureaus
                        </p>
                      </td>
                      <td className="align_center">Yes</td>
                      <td className="align_center">No</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <h4>For our marketing purposes -</h4>
                        <p>to offer our products and services to you</p>
                      </td>
                      <td className="align_center">Yes</td>
                      <td className="align_center">No</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <h4>
                          For joint marketing with other financial companies
                        </h4>
                      </td>
                      <td className="align_center">No</td>
                      <td className="align_center">We don't share</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <h4>
                          For our affiliates' everyday business purposes -
                        </h4>
                        <p>
                          information about your transactions and experiences
                        </p>
                      </td>
                      <td className="align_center">Yes</td>
                      <td className="align_center">No</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <h4>
                          For our affiliates' everyday business purposes -
                        </h4>
                        <p>information about your creditworthiness</p>
                      </td>
                      <td className="align_center">Yes</td>
                      <td className="align_center">Yes</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <h4>For our affiliates to market to you</h4>
                      </td>
                      <td className="align_center">Yes</td>
                      <td className="align_center">Yes</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <h4>For nonaffiliates to market to you</h4>
                      </td>
                      <td className="align_center">No</td>
                      <td className="align_center">We don't share</td>
                    </tr>
                  </tbody>
                </table>
                <div className="pp-wrap clearfix">
                  <div className="fact_block">
                    <div className="fact_block_left">To limit our sharing</div>
                    <div className="fact_block_right">
                      <p>
                        Call 866 343 4345 - our menu will prompt you through
                        your choices
                      </p>
                      <p>
                        <b>Please note:</b>
                      </p>
                      <p>
                        If you are a <em>new</em> customer, we can begin sharing
                        your information 30 days from the date we sent this
                        notice. When you are <em>no longer</em> our customer, we
                        continue to share your information as described in this
                        notice.
                      </p>
                      <p>
                        However, you can contact us at any time to limit our
                        sharing.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="pp-wrap clearfix">
                  <div className="fact_block">
                    <div className="fact_block_left">Questions?</div>
                    <div className="fact_block_right">
                      <p>
                        Call 866 343 4345 <br />
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
                <table className="table">
                  <tbody>
                    <tr>
                      <td className="details_head bold" colSpan="2">
                        Who we are
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <h4>Who is providing this notice?</h4>
                      </td>
                      <td>
                        <p>First Help Financial, LLC </p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <tbody>
                    <tr>
                      <td className="details_head bold" colSpan="2">
                        What We Do
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <h4>
                          How does First Help Financial protect my personal
                          Information ?
                        </h4>
                      </td>
                      <td>
                        <p>
                          To protect your personal information from unauthorized
                          access and use, we use security measures that comply
                          with federal law. These measures include computer
                          safeguards and secured files and buildings.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <h4>
                          How does First Help Financial collect my personal
                          Information ?
                        </h4>
                      </td>
                      <td>
                        <p>
                          We collect your personal information, for example,
                          when you
                        </p>
                        <ul>
                          <li>
                            give us your contact information or apply for
                            financing
                          </li>
                          <li>pay your bills or provide account information</li>
                          <li>give us your income information</li>
                        </ul>
                        <p>
                          We also collect your personal information from others,
                          such as credit bureaus, affiliates, or other
                          companies.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <h4>Why can't I limit all sharing?</h4>
                      </td>
                      <td>
                        <p>Federal law gives you the right to limit only</p>
                        <ul>
                          <li>
                            sharing for affiliates' everyday business
                            purposes-information about your creditworthiness{" "}
                          </li>
                          <li>
                            affiliates from using your information to market to
                            you
                          </li>
                          <li>sharing for nonaffiliates to market to you</li>
                        </ul>
                        <p>
                          State laws and individual companies may give you
                          additional rights to limit sharing.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td>
                        <h4>
                          What happens when I limit sharing for an account I
                          hold jointly with someone else?
                        </h4>
                      </td>
                      <td>
                        <p>
                          Your choices will apply to everyone on your account.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <tbody>
                    <tr>
                      <td className="details_head" colSpan="2">
                        Definitions
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td style={{ width: "23 %" }}>
                        <h4>Affiliates</h4>
                      </td>
                      <td>
                        <p>
                          Companies related by common ownership or control. They
                          can be financial and nonfinancial companies.
                        </p>
                        <ul>
                          <li>
                            <i className="black">
                              Our affiliates include insurance companies, such
                              as Amparo Insurance, LLC in Affiliates
                            </i>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td style={{ width: "23 %" }}>
                        <h4>Nonaffiliates</h4>
                      </td>
                      <td>
                        <p>
                          Companies not related by common ownership or control.
                          They can be financial and nonfinancial companies.
                        </p>
                        <ul>
                          <li>
                            <i className="black">
                              First Help Financial does not share with
                              nonaffiliates so they can market to you
                            </i>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <td style={{ width: "23 %" }}>
                        <h4>Joint marketing</h4>
                      </td>
                      <td>
                        <p>
                          A formal agreement between nonaffiliated financial
                          companies that together market financial products or
                          services to you.
                        </p>
                        <ul>
                          <li>
                            <i className="black">
                              First Help Financial does not jointly market.
                            </i>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="page-footer">
            <p>
              First Help Financial - 160 Gould St. Suite 100, Needham, MA 02494
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default PrivacyNotice;
