import React from "react";

import Header from "./header";
import Footer from "./footer";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy-policy-page">
        <Header />
        <div className="privacy-policy text-small">
          <h1 className="text-big">PRIVACY POLICY</h1>
          <br />
          <div className="ordered-lists">
            <p>
              Here is a summary of what you will learn in this Privacy Policy:
            </p>
            <ol>
              <li>
                Personal Information We Collect
                <ol>
                  <li>Categories of personal information collected</li>
                  <li>How we obtain your personal information</li>
                  <li>How we use your personal information</li>
                  <li>How long we retain your personal information</li>
                  <li>How we disclose your personal information</li>
                  <li>Sale of personal information</li>
                  <li>Sharing of personal information</li>
                </ol>
              </li>
              <li>
                Rights for California Residents
                <ol>
                  <li>Right to Know</li>
                  <li>Right to Delete</li>
                  <li>Right to Correct</li>
                  <li>Right to Limit Use of Sensitive Personal Information</li>
                  <li>Right to Opt-Out</li>
                  <li>Right to Non-Discrimination</li>
                  <li>
                    Submitting a Request to Know, Request to Delete or Request
                    to Correct
                    <ol>
                      <li>How to submit a request</li>
                      <li>Authorized agents</li>
                      <li>Response timing and delivery method</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li>Social Media</li>
              <li>Do Not Track Signals</li>
              <li>Email Communication</li>
              <li>Children</li>
              <li>Monitoring and Recording</li>
              <li>Changes to the Privacy Policy</li>
              <li>Contact us</li>
            </ol>
          </div>
          <br />
          <p>
            This <strong>Privacy Policy</strong> supplements the information
            contained in the <strong>First Help Financial</strong>
            <br />
            <a href="/privacy-notice" target="_blank" rel="noreferrer">
              Privacy Notice
            </a>
            of
            <a href="http://www.firsthelpfinancial.com">
              http://www.firsthelpfinancial.com
            </a>
            and <strong>First Help Financial, LLC </strong> (“First Help,” “we,”
            “us,” or “our”) and applies solely to visitors, users, applicants,
            customers, commercial contacts (“you”). By using our website or
            services, you agree to this Privacy Policy. This version of the
            Privacy Policy supersedes any prior version.
          </p>
          <br />
          <div className="ordered-lists">
            <ol>
              <li>
                <strong>
                  <u>PERSONAL INFORMATION WE COLLECT</u>
                </strong>
                <br />
                <ul>
                  <li>
                    We collect information that identifies, relates to,
                    describes, references, is capable of being associated with,
                    or could reasonably be linked, directly or indirectly, with
                    a particular consumer or device (“personal information”).
                  </li>
                  <li>
                    State privacy laws generally exclude publicly available
                    information, de-identified or aggregated information, and
                    information covered by certain industry-specific privacy
                    laws such as the Fair Credit Reporting Act (FCRA),
                    Gramm-Leach-Bliley Act (GLBA), and California Financial
                    Information Privacy Act (FIPA) from the definition of
                    personal information.
                  </li>
                </ul>
                <br />
                <strong>
                  AS A COURTESY, THE FOLLOWING DISCLOSURES INCLUDE INFORMATION
                  THAT IS EXEMPT FROM STATE PRIVACY LAWS SUCH AS THE CALIFORNIA
                  CONSUMER PRIVACY ACT (“CCPA”). THE COURTESY DISCLOSURES ARE
                  MADE WITH A RESERVATION OF ALL RIGHTS THAT WE HAVE UNDER CCPA
                  AND OTHER STATE PRIVACY LAWS.
                </strong>
                <br />
                <br />
                <ol>
                  <li>
                    <strong>
                      Categories of personal information collected:
                    </strong>
                    <p>
                      We collect the following categories of personal
                      information:
                    </p>
                    <br />
                    <ul>
                      <li>
                        <strong>
                          <u>“Sensitive Personal Information”</u>
                        </strong>
                        such as Social Security numbers (SSNs), Driver’s
                        license, financial account or card numbers, Precise
                        geolocation, Racial and ethnic characteristics, Contents
                        of mail, email and text messages, Genetic and biometric
                        data.
                      </li>
                      <li>
                        <strong>
                          <u>“Identifiers”</u>
                        </strong>
                        such as name, alias, address, unique identifier,
                        internet protocol address, email address, account
                        number, Social Security Number, or government
                        identification number,
                      </li>
                      <li>
                        <strong>
                          <u>“Other Personal Information”</u>
                        </strong>
                        such as name, signature, Social Security Number,
                        physical characteristics or description, address,
                        telephone number, passport number, driver's license or
                        state identification card number, insurance policy
                        number, education, employment, employment history, bank
                        account number, credit card number, debit card number,
                        or any other financial information. Some personal
                        information included in this category may overlap with
                        other categories,
                      </li>
                      <li>
                        <strong>
                          <u>“Protected Characteristics”</u>
                        </strong>
                        under California or federal law for classifications such
                        as age (40 years or older), race, color, ancestry,
                        national origin, citizenship, marital status, medical
                        condition, physical or mental disability, sex (including
                        gender, gender identity, gender expression, pregnancy or
                        childbirth and related medical conditions), or veteran
                        or military status,
                      </li>
                      <li>
                        <strong>
                          <u>“Commercial Information”</u>
                        </strong>
                        such as records of personal property, products or
                        services purchased, obtained, or considered, or other
                        purchasing or consuming histories or tendencies,
                      </li>
                      <li>
                        <strong>
                          <u>“Internet or Network Activity”</u>
                        </strong>
                        such as browsing history, search history, information on
                        a consumer's interaction with a website, application, or
                        advertisement,
                      </li>
                      <li>
                        <strong>
                          <u>“Biometric Information”</u>
                        </strong>
                        such as genetic, physiological, behavioral, and
                        biological characteristics, or activity patterns used to
                        extract a template or other identifier or identifying
                        information, such as, fingerprints, faceprints, and
                        voiceprints, iris or retina scans, keystroke, or gait,
                      </li>
                      <li>
                        <strong>
                          <u>“Geolocation”</u>
                        </strong>
                        such as physical movements or location,
                      </li>
                      <li>
                        <strong>
                          <u>“Sensory Data”</u>
                        </strong>
                        such as audio, electronic, or visual information when
                        you participate in telephone screens or video interviews
                        with us,
                      </li>
                      <li>
                        <strong>
                          <u>
                            “Professional or Employment Related Information”
                          </u>
                        </strong>
                        such as current or past job history or performance
                        evaluations, and
                      </li>
                      <li>
                        <strong>
                          <u>“Inferences”</u>
                        </strong>
                        such as profile reflecting a person's preferences,
                        characteristics, psychological trends, predispositions,
                        behavior, attitudes, intelligence, abilities, and
                        aptitudes.
                      </li>
                    </ul>
                  </li>
                  <br />
                  <li>
                    <strong>How we obtain your personal information:</strong>
                    <br />
                    <br />
                    <table border="1">
                      <tbody>
                        <tr>
                          <td>Directly from you.</td>
                          <td>
                            You enter or provide us with information online, by
                            email, by phone, or by document upload. For example,
                            when you register for an account, show us your
                            government issued ID, or setup payment processing
                            details.
                          </td>
                        </tr>
                        <tr>
                          <td>Indirectly from you.</td>
                          <td>
                            We collect information about you and your account
                            through the services we provide.
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Directly and indirectly from you based on activity
                            on our website.
                          </td>
                          <td>
                            For example, from submissions through our website or
                            website usage details collected automatically.
                          </td>
                        </tr>
                        <tr>
                          <td>
                            From vendors that interact with us in connection
                            with the products and services we provide.
                          </td>
                          <td>
                            For example, credit reporting agencies or companies
                            that provide data we use in protecting you and our
                            products from fraud and identity theft.
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                  <br />
                  <li>
                    <strong>How we use your personal information:</strong>
                    <p>
                      We may collect, use, or disclose your personal information
                      for one or more of the following purposes:
                    </p>
                    <br />
                    <ol>
                      <li>
                        Performing services such as maintaining or servicing
                        accounts, providing customer service, processing, or
                        fulfilling transactions, verifying customer information,
                        processing payments, providing advertising or marketing
                        services, providing analytic services, or providing
                        storage.Examples of such activities:
                        <ul>
                          <li>
                            To fulfill or meet the reason for which the
                            information is provided.
                          </li>
                          <li>
                            To provide you with information, products, or
                            services that you request from us.
                          </li>
                          <li>
                            To provide you with email alerts and other notices
                            concerning our products or services, or events or
                            news, that may be of interest to you.
                          </li>
                          <li>
                            To carry out our obligations and enforce our rights
                            arising from any contracts entered into between you
                            and us.
                          </li>
                        </ul>
                      </li>
                      <li>
                        Undertaking internal research for technological
                        development and demonstration.
                      </li>
                      <li>
                        Debugging to identify and repair errors that impair
                        existing intended functionality.
                      </li>
                      <li>
                        Helping to ensure security and integrity to the extent
                        the use of the consumer's personal information is
                        reasonably necessary and proportionate for these
                        purposes.
                      </li>
                      <li>Auditing related to counting unique visitors.</li>
                      <li>
                        Providing advertising and marketing services, but not
                        cross-context behavioral advertising.
                      </li>
                      <li>
                        Short-term, transient use, including, but not limited
                        to, nonpersonalized advertising shown as part of a
                        consumer’s current interaction with the business,
                        provided that the consumer’s personal information is not
                        disclosed to another third party and is not used to
                        build a profile about the consumer or otherwise alter
                        the consumer’s experience outside the current
                        interaction with the business.
                      </li>
                      <li>
                        Undertaking activities to verify or maintain the quality
                        or safety of a service or device that is owned,
                        manufactured, manufactured for, or controlled by us, and
                        to improve, upgrade, or enhance the service or device
                        that is owned, manufactured, manufactured for, or
                        controlled by us.
                      </li>
                      <li>
                        To comply with our legal or regulatory obligations.
                      </li>
                    </ol>
                    <br />
                  </li>
                  <li>
                    <strong>
                      How long we retain your personal information:
                    </strong>
                    <p>
                      Based on contractual requirements and/or legal
                      requirements, personal information is maintained for a
                      specified period of time, as required by legal,
                      regulatory, or industry requirements. Additional
                      information such as emails and vendor agreements are
                      maintained for business purposes for an indefinite amount
                      of time.
                    </p>
                  </li>
                  <br />
                  <li>
                    <strong> How we disclose your personal information:</strong>
                    <p>
                      We may disclose your personal information to a service
                      provider, contractor, or third party. When we disclose
                      personal information to a service provider or contractor,
                      we enter into a contract that describes the purpose and
                      requires the service provider to both keep that personal
                      information confidential and not use it for any purpose
                      except performing the contract or as otherwise allowed by
                      law. We disclose personal information to third parties as
                      follows:
                    </p>
                    <br />
                    <ul>
                      <li>
                        With
                        <u>
                          account servicing systems, wire transfer vendors,
                          payment processors, and fraud prevention vendors
                        </u>
                        we disclose Sensitive Personal Information, Identifiers,
                        Other Personal Information, Protected Characteristics,
                        Commercial Information, Geolocation, Biometric Data,
                        Internet and Network Activity.
                      </li>
                      <li>
                        With <u>collateral recovery and disposition vendors</u>
                        we disclose Identifiers, Other Personal Information,
                        Geolocation .
                      </li>
                      <li>
                        With<u>credit reporting agencies and dispute vendors</u>
                        we disclose Sensitive Personal Information, Identifiers,
                        Other Personal Information, Protected Characteristics,
                        Commercial Information, Inferences. Identifiers and
                        Other Personal Information.
                      </li>
                      <li>
                        With <u>shipping providers</u> we discloseIdentifiers
                        and Other Personal Information.
                      </li>
                      <li>
                        With our
                        <u>
                          data analytics, network monitoring services, and
                          incident response providers,
                        </u>
                        we disclose Sensitive Personal Information, Identifiers,
                        Other Personal Information, Protected Characteristics,
                        Commercial Information, Internet or Network Activity,
                        Biometric Information, Geolocation, Sensory Data,
                        Professional or Employment Related Information, and
                        Inferences.
                      </li>
                      <li>
                        With
                        <u>
                          word processing, communication applications, and
                          calendar providers
                        </u>
                        we disclose Sensitive Personal Information, Identifiers,
                        Other Personal Information, Protected Characteristics,
                        Commercial Information, Internet or Network Activity,
                        Biometric Information, Geolocation, Sensory Data,
                        Professional or Employment Related Information, and
                        Inferences.
                      </li>
                      <li>
                        With our <u>advisors</u> such as lawyers, accountants,
                        auditors, banks / lenders, insurers, and consultants, we
                        disclose Sensitive Personal Information, Identifiers,
                        Other Personal Information, Protected Characteristics,
                        Commercial Information, Internet or Network Activity,
                        Biometric Information, Geolocation, Sensory Data,
                        Professional or Employment Related Information, and
                        Inferences.
                      </li>
                      <li>
                        With our <u>governmental and regulatory agencies,</u>,
                        we disclose Sensitive Personal Information, Identifiers,
                        Other Personal Information, Protected Characteristics,
                        Commercial Information, Internet or Network Activity,
                        Biometric Information, Geolocation, Sensory Data,
                        Professional or Employment Related Information, and
                        Inferences.
                      </li>
                      <li>
                        With
                        <u>
                          law enforcement, government entities, courts, and
                          pursuant to legal process
                        </u>
                        where required by law, we disclose Sensitive Personal
                        Information, Identifiers, Other Personal Information,
                        Protected Characteristics, Commercial Information,
                        Internet or Network Activity, Biometric Information,
                        Geolocation, Sensory Data, Professional or Employment
                        Related Information, and Inferences.
                      </li>
                      <li>
                        With our <u>affiliates </u>such as lawyers, accountants,
                        auditors, banks / lenders, insurers, and consultants, we
                        disclose Sensitive Personal Information, Identifiers,
                        Other Personal Information, Protected Characteristics,
                        Commercial Information, Internet or Network Activity,
                        Biometric Information, Geolocation, Sensory Data,
                        Professional or Employment Related Information, and
                        Inferences.
                      </li>
                    </ul>
                  </li>
                  <br />
                  <li>
                    <strong>Sale of personal information:</strong>
                    <p>
                      We do not sell your personal information. We do not sell
                      the personal information of minors under 16 years of age
                      without affirmative authorization.
                    </p>
                  </li>
                  <br />
                  <li>
                    <strong> Sharing of personal information:</strong>
                    <p>
                      We do not share your personal information. Our site is not
                      intended for or directed to minors. We do not knowingly
                      share the personal information of minors under 16 years of
                      age. (“Sharing” is means the disclosure of personal
                      information for cross-contextual behavioral advertising.)
                    </p>
                  </li>
                  <br />
                </ol>
              </li>
              <li>
                <strong>
                  <u>RIGHTS FOR CALIFORNIA RESIDENTS</u>
                </strong>
                <p>
                  We adopt this portion of the Privacy Policy to comply with the
                  California Consumer Privacy Act (“CCPA”) and other California
                  privacy laws. Any terms defined in the CCPA have the same
                  meaning when used here. <br />
                  <br />
                  The CCPA provides California residents (“consumers”) with
                  specific rights regarding their personal information - the
                  Right to Know, the Right to Delete, the Right to Correct, the
                  Right to Limit use of Sensitive Personal Information, the
                  Right to Opt-Out of the Sale or Sharing of Personal
                  Information, and the Right to Non-Discrimination. This section
                  describes your CCPA rights and explains how to exercise those
                  rights, if applicable.
                </p>
                <br />
                <ol>
                  <li>
                    <strong>Right to Know</strong>
                    <p>
                      You have the right to request that we disclose certain
                      information to you about our collection, use, and
                      disclosures of your personal information (“Right to
                      Know”). Once we receive and verify your request, we will
                      disclose to you:
                      <br />
                      <br />
                      <ul>
                        <li>
                          Categories of Personal Information Collected,
                          Disclosed, Sold, and/or Shared
                          <ul>
                            <li>
                              The categories of personal information we
                              collected about you.
                            </li>
                            <li>
                              The categories of sources for the personal
                              information we collected about you.
                            </li>
                            <li>
                              Our business or commercial purpose for collecting
                              that personal information.
                            </li>
                            <li>
                              The categories of third parties with whom we share
                              that personal information.
                            </li>
                            <li>
                              If we disclosed your personal information for a
                              business purpose, the categories of personal
                              information shared with each category of third
                              party recipients.
                            </li>
                            <li>
                              If we sold or shared your personal information for
                              cross-contextual behavioral advertising, the
                              categories of personal information sold or shared.
                            </li>
                          </ul>
                        </li>
                        <li>
                          Specific Information
                          <ul>
                            <li>
                              The specific pieces of personal information we
                              collected about you.
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </p>
                  </li>
                  <br />
                  <li>
                    <strong>Right to Delete</strong>
                    <p>
                      You have the right to request that we delete any of your
                      personal information that we collected and retained
                      (“Right to Delete”). Once we receive and verify your
                      request, we will delete, de-identify, or aggregate your
                      personal information unless an exception applies. We may
                      deny your Request to Delete if retaining the personal
                      information is necessary for us or our service providers
                      to:
                    </p>
                    <ul>
                      <li>
                        Complete the transaction for which the personal
                        information was collected, provide a good or service
                        requested by the consumer, or reasonably anticipated
                        within the context of a business's ongoing business
                        relationship with the consumer, or otherwise perform a
                        contract between the business and the consumer.
                      </li>
                      <li>
                        Help to ensure security and integrity to the extent the
                        use of the consumer’s personal information is reasonably
                        necessary and proportionate for those purposes.
                      </li>
                      <li>
                        Debug to identify and repair errors that impair existing
                        intended functionality.
                      </li>
                      <li>
                        Exercise free speech, ensure the right of another
                        consumer to exercise his or her right of free speech, or
                        exercise another right provided for by law.
                      </li>
                      <li>
                        Comply with the California Electronic Communications
                        Privacy Act pursuant to Chapter 3.6 (commencing with
                        Section 1546) of Title 12 of Part 2 of the Penal Code.
                      </li>
                      <li>
                        To enable solely internal uses that are reasonably
                        aligned with the expectations of the consumer based on
                        the consumer's relationship with the business and
                        compatible with the context in which the consumer
                        provided the information.
                      </li>
                      <li>Comply with a legal obligation.</li>
                    </ul>
                  </li>
                  <br />
                  <li>
                    <strong> Right to Correct</strong>
                    <p>
                      You have the right to request that we correct your
                      personal information if it is inaccurate (Right to
                      Correct). Once we verify your identity and confirm that
                      your personal information is inaccurate, we will correct
                      your personal information (and direct our service
                      providers to do the same).
                    </p>
                  </li>
                  <br />
                  <li>
                    <strong>
                      Right to Limit Use of Sensitive Personal Information
                    </strong>
                    <p>
                      You have the right to direct a business that collects your
                      sensitive personal information to limit its use to uses
                      which are necessary to perform the services or provide the
                      goods reasonably expected. However, we only use Sensitive
                      Personal Information as exempt from the CCPA, to provide
                      the goods and services requested by you, to prevent,
                      detect, and investigate security incidents, to resist
                      malicious, deceptive, fraudulent, or illegal actions and
                      to prosecute those responsible for such actions, to ensure
                      people’s physical safety, to perform services on our
                      behalf, to verify or maintain the quality or safety of our
                      products, services, and devices.
                    </p>
                  </li>
                  <br />
                  <li>
                    <strong>Right to Opt-Out:</strong>
                    <p>
                      The CCPA gives consumers the right to opt-out of
                      <br />
                      (1) the sale of their personal information,
                      <br />
                      (2) the sharing of their personal information for
                      cross-context behavior advertising, or <br />
                      (3) for use in automated decision making.
                    </p>
                    <br />
                    <ol>
                      <li>
                        <strong>Sale of Personal Information:</strong>
                        <p>
                          We do not sell your personal information. We do not
                          sell the personal information of minors under 16 years
                          of age without affirmative authorization.
                        </p>
                      </li>
                      <br />
                      <li>
                        <strong>Sharing of Personal Information:</strong>
                        <p>
                          We do not share your personal information. Our site is
                          not intended for or directed to minors. We do not
                          knowingly share the personal information of minors
                          under 16 years of age.
                        </p>
                      </li>
                      <br />
                      <li>
                        <strong>Automated Decision-Making:</strong>
                        <p>
                          The CCPA gives consumers the right to opt-out of the
                          use of automated decision-making technology in
                          connection with decisions about the consumer’s work
                          performance, economic situation, health, personal
                          preferences, interests, reliability, behavior,
                          location, or movements. However, we do not use
                          automated decision-making technology for personal
                          information that is not covered by FCRA, GLBA, and
                          CalFIPA.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <br />
                  <li>
                    <strong>Right to Non-Discrimination</strong>
                    <p>
                      We will not discriminate against you for exercising any of
                      your CCPA rights. Unless permitted by the CCPA, we will
                      not deny you goods or services, charge you different
                      prices or rates for goods or services, including through
                      granting discounts or other benefits, or imposing
                      penalties, provide you a different level or quality of
                      goods or services, suggest that you may receive a
                      different price or rate for goods or services or a
                      different level or quality of goods or services.
                    </p>
                  </li>
                  <br />
                  <li>
                    <strong>
                      Submitting a Request to Know, Request to Delete or Request
                      to Correct
                    </strong>
                    <br />
                    <br />
                    <ol>
                      <li>
                        <strong>How to Submit a Request:</strong>
                        <ul>
                          <li>
                            Call us at
                            <a href="tel:866-343-4345">(866) 343-4345</a>
                          </li>
                          <li>
                            Email us at
                            <a href="mailto:privacy@firsthelp.com">
                              privacy@firsthelp.com
                            </a>
                          </li>
                          <br />
                          <p>
                            Only 1) you or 2) a person authorized by you to act
                            on your behalf, may make a verifiable consumer
                            request related to your personal information. You
                            may also make a request on behalf of your minor
                            child. You may only make a Request to Know twice
                            within a 12-month period. A Request to Know, Request
                            to Delete, or Request to Correct must provide
                            sufficient information that allows us to reasonably
                            verify you are the person making the request:
                          </p>
                          <ul>
                            <li>Name,</li>
                            <li>Address,</li>
                            <li>Phone,</li>
                            <li>Email,</li>
                            <li>Company Name (if applicable),</li>
                            <li>Account Number (if known),</li>
                            <li>VIN Number (if known),</li>
                            <li>Date of Birth, and</li>
                            <li>Last 4 of SSN.</li>
                            <li>
                              Describe your request with sufficient detail that
                              allows us to properly understand, evaluate, and
                              respond to it.
                            </li>
                            <li>
                              If you are submitting a Request to Know specific
                              pieces of personal information, you will need to
                              submit a declaration under the penalty of perjury.
                            </li>
                          </ul>
                        </ul>
                      </li>
                      <br />
                      <li>
                        <strong>Authorized agents:</strong>
                        <p>
                          Before we can respond to a Request to Know, Request to
                          Delete, or Request to Correct submitted by an
                          authorized agent, we need to verify not only that
                          person or entity’s authority to act on your behalf but
                          also verify the identity of the authorized agent.
                          <br />
                          If you are authorized to submit a request on behalf of
                          a California resident, please email us at:
                          <a href="mailto:privacy@firsthelp.com">
                            privacy@firsthelp.com
                          </a>
                          and provide the following information:
                        </p>
                        <ul type="1">
                          <li>
                            To verify your authorization to request on behalf of
                            a California resident, please attach a copy of
                            <u>one or more</u> of the following to your request
                            email:
                            <ul>
                              <li>
                                written permission from the California resident,
                              </li>
                              <li>or a valid power of attorney</li>
                            </ul>
                          </li>
                          <li>
                            To verify your identity, please attach copies of the
                            following to your request email:
                            <ul>
                              <li>
                                Valid Government Issued ID (not expired)
                                <strong>
                                  <u>AND</u>
                                </strong>
                              </li>
                              <li>
                                a utility bill, bank statement, or similar
                                documentation to verify your name and address.
                              </li>
                            </ul>
                          </li>
                          <li>
                            To verify the identity of the consumer for whom you
                            are submitting the request, please attach the
                            following with your request email:
                            <ul>
                              <li>Address,</li>
                              <li>Phone,</li>
                              <li>Email,</li>
                              <li>Company Name (if applicable),</li>
                              <li>Account Number (if known),</li>
                              <li>VIN Number (if known),</li>
                              <li>Date of Birth, and</li>
                              <li>Last 4 of SSN.</li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <br></br>
                      <li>
                        <strong>Response timing and delivery method:</strong>
                        <p>
                          We will acknowledge receipt of your request within 10
                          business days of its receipt. We will respond to a
                          request within 45 days of its receipt. If we require
                          more time (up to 90 days), we will inform you of the
                          reason and extension period in writing. We will
                          deliver our written response by mail or
                          electronically, at your option. The response we
                          provide will also explain the reasons we cannot comply
                          with a request, if applicable. We do not charge a fee
                          to process or respond to your request unless it is
                          excessive, repetitive, or manifestly unfounded. If we
                          determine that the request warrants a fee, we will
                          tell you why we made that decision and provide you
                          with a cost estimate before completing your request.
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <br />

              <li>
                <strong>
                  <u>SOCIAL MEDIA</u>
                </strong>
                <p>
                  We encourage you to review your privacy options and settings
                  with the social media platforms and networks you use to
                  understand what choices you have about disclosing your
                  information through those platforms and networks with us.
                </p>
              </li>
              <br />
              <li>
                <strong>
                  <u>DO NOT TRACK SIGNALS</u>
                </strong>
                <p>Our site does not respond to Do Not Track Signals</p>
              </li>
              <br />
              <li>
                <strong>
                  <u>EMAIL COMMUNICATIONS</u>
                </strong>
                <p>
                  You may opt out of email communications from us by following
                  the unsubscribe instructions provided in the e-mail or by
                  contacting us directly (please see contact information below).
                  Please note that while we will apply your opt out request
                  within ten (10) business days for marketing communications, we
                  may still contact you in relation to your account servicing,
                  including notices of any updates to our Privacy Notice and
                  Privacy Policy.
                </p>
              </li>
              <br />
              <li>
                <strong>
                  <u>CHILDREN</u>
                </strong>
                <p>
                  Our Website and services are not directed toward children, and
                  we do not intentionally gather Personal Information from
                  website visitors who are under the age of 16. If we learn that
                  a child under 16 has submitted personal information to us via
                  our Website, we will attempt to delete the information as soon
                  as possible. If you believe that we might have any personal
                  information from a child under 16, please contact us at
                  <a href="mailto:privacy@firsthelp.com">
                    privacy@firsthelp.com.
                  </a>
                </p>
              </li>
              <br />
              <li>
                <strong>
                  <u>MONITORING AND RECORDING</u>
                </strong>
                <p>
                  By visiting our Website and/or using our services, you
                  acknowledge and agree that your activity will be monitored or
                  recorded, including by third party vendors for our internal
                  use. Additionally, you acknowledge and agree that we may
                  monitor and record all calls (inbound and outbound),
                  communication, and Website interaction. By communicating with
                  us (via telephone, website, platform, or chatbox) and/or by
                  using our services, you acknowledge and agree to such
                  recordings and monitoring.
                </p>
              </li>
              <br />
              <li>
                <strong>
                  <u>CHANGES TO THIS PRIVACY POLICY</u>
                </strong>
                <p>
                  We reserve the right to amend this Privacy Policy at our
                  discretion and at any time. When we make material changes to
                  this privacy policy, we will notify you by posting a new
                  version on our website homepage.
                </p>
              </li>
              <br />
              <li>
                <strong>
                  <u>CONTACT US</u>
                </strong>
                <p>
                  If you have any questions or comments about this Privacy
                  Policy, the ways in which we collect and use your personal
                  information, your choices and rights regarding such use, or
                  wish to exercise your rights under California law, please do
                  not hesitate to contact us at:
                </p>
                <br />
                <div className="space-50">
                  <p>
                    Phone: <a href="tel:866-343-4345">(866) 343-4345</a>
                  </p>
                  <p>
                    Website:
                    <a href="https://www.firsthelpfinancial.com">
                      www.firsthelpfinancial.com
                    </a>
                  </p>
                  <p>
                    Email:
                    <a href="mailto:privacy@firsthelp.com">
                      privacy@firsthelp.com
                    </a>
                  </p>
                  <p>
                    Postal Address:
                    <div className="space-50">
                      <p>First Help Financial, LLC</p>
                      <p>Attn: Privacy</p>
                      <p>160 Gould Street, Suite 100</p>
                      <p>Needham, MA 02494</p>
                    </div>
                  </p>
                </div>
                <br />
                <br />
                <p>Effective Date 02/19/2024</p>
              </li>
            </ol>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default PrivacyPolicy;
