import React from "react";

import Header from "./header";
import Footer from "./footer";

const TermsOfService = () => {
  return (
    <>
      <div className="terms-of-service-page">
        <Header />
        <div className="terms-of-service text-small">
          <p className="text-big">TERMS OF SERVICE</p>
          <p>
            THESE TERMS INCLUDE LIMITATIONS ON FIRST HELP’S LIABILITY AND YOUR
            AVAILABLE REMEDIES IF WE EVER HAVE A LEGAL DISPUTE. THIS INCLUDES A
            CLASS ACTION WAIVER AND AN ARBITRATION CLAUSE, WHICH MEANS WE AGREE
            TO HAVE DISPUTES RESOLVED ON AN INDIVIDUAL BASIS BY A NEUTRAL
            ARBITRATOR RATHER THAN BY A JUDGE OR JURY IN COURT. PLEASE SEE
            SECTION 10 BELOW FOR MORE INFORMATION.
          </p>
          <br />
          <ol>
            <li>
              <strong>
                <u>SCOPE AND APPLICABILITY</u>
              </strong>
              <p>
                Please review the following
                <strong> Terms of Service (“Terms”)</strong> concerning your use
                of
                <a
                  href="https://www.firsthelpfinancial.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  www.firsthelpfinancial.com
                </a>
                (“Site”). The Terms are a binding contract between you and
                <strong>First Help Financial, LLC</strong> (“we,” “us,” “our,”
                “First Help”). By accessing the Site, and accessing, using, or
                downloading any materials from the Site, you agree to follow and
                be bound by these Terms and our
                <a href="/privacy-notice" target="_blank" rel="noreferrer">
                  Privacy Notice
                </a>
                , and
                <a href="/privacy-policy">Privacy Policy</a>
                (collectively “Privacy Policies”).
                <br />
                <p>
                  First Help reserves the right to update or modify these Terms
                  at any time and without prior notice. Your use of the Site
                  following any such change constitutes your agreement to follow
                  and be bound by the Terms as changed. For this reason, we
                  encourage you to review these Terms whenever you use this
                  Site. Each instance of access or use of the Site will serve as
                  acceptance of the updated Terms.
                </p>
                <br />
                <strong>
                  <i>
                    IF YOU DO NOT AGREE WITH ANY OF THESE TERMS AND CONDITIONS,
                    DO NOT USE THE SITE OR THE SERVICES OF FIRST HELP.
                  </i>
                </strong>
              </p>
            </li>
            <br />
            <li>
              <strong>
                <u>OUR SERVICE</u>
              </strong>
              <p>
                First Help provides financing for vehicles and services the
                related accounts (“Service”). The Site includes instructions for
                accessing the Service and completing a transaction, which may
                include one or more of the following steps:
                <br />
                (i) you provide requested biographical information. <br />
                (ii) you create a unique password. <br />
                (iii) you respond to a series of questions that allows us to
                confirm your identity. <br />
                (iv) making monthly payments. <br />
                (v) how dealers can apply to on-board with First Help. and
                <br />
                (vi) how to apply for jobs at First Help. If at any time you
                have questions about the Site or the Service, you can reach us
                via e-mail at
                <a href="mailto:privacy@firsthelp.com">
                  privacy@firsthelp.com.
                </a>
                <br />
                <br />
                Although First Help attempts to provide accurate information on
                the Site, it assumes no responsibility for the accuracy of the
                information. First Help may change the Service at any time
                without notice. First Help may periodically make changes to the
                Site.
              </p>
            </li>
            <br />
            <li>
              <strong>
                <u>COMMUNICATION</u>
              </strong>
              <p>
                You acknowledge and agree that First Help may communicate with
                you in a variety of different ways including but not limited to
                email, telephone, fax, voicemail, letters sent by post, text and
                SMS messaging. Some communications occur through multiple
                channels. Such communications are only intended for you. By
                using our Service and/or Site, you acknowledge and agree to the
                following communications-related practices:
              </p>
              <br />
              <p>
                <em>
                  <u>Consent to Contact:</u>
                </em>
                By engaging with our Site and/or obtaining our Service, you
                agree that we may contact you by any method, including but not
                limited to contacts to respond to your inquiries, service your
                account or collect any amount you owe, by phone, text, email, or
                mail. We are permitted to use any address, telephone number, or
                email address that you or any of your principals or agents
                provide to us. You, your principals, and your agents agree to
                provide accurate and current contact information and only give
                us phone numbers and email addresses that belong to you. Text
                messages may include, but are not limited to, reminders,
                notifications and collection communications.
              </p>
              <br />

              <p>
                When you give us a telephone number, you are providing your
                express consent permitting us (and any party acting on our
                behalf) to contact you at the telephone number you provide. We
                may call you and send you text messages. You agree that we may
                leave prerecorded or artificial voice messages. You also agree
                that we may use automatic telephone dialing systems in
                connection with call or text messages sent by us to any
                telephone number you give us, even if the telephone number is a
                mobile phone number or other communication service for which you
                or the subscriber is charged.
              </p>
              <br />

              <p>
                <em>
                  <u>Recording & Monitoring of Communications:</u>
                </em>
                Your communications with us via our Service and/or our Site,
                email, and telephone are or may be recorded and/or monitored by
                us and our vendors. By using such communication methods you are
                consenting to the recording and/or monitoring of the same.
              </p>
              <br />
            </li>
            <li>
              <strong>
                <u>YOUR REPRESENTATIONS, OBLIGATIONS, AND CONSENT</u>
              </strong>
              <p>
                <i>
                  <u>Registration: </u>
                </i>
                When you create an online account with First Help, you
                acknowledge that you are over the age of 18, and that the
                information you provide to us is accurate, complete, and current
                at all times. You agree to provide us with a license (to the
                extent legally necessary) to utilize your data and information
                in order to complete the Service.
              </p>
              <br />
              <p>
                Please review our Privacy Policies. By accessing or using the
                Site, you agree to our Privacy Policies, and you understand that
                when using the Site and/or Service, you will be asked to provide
                certain personal information which may be sensitive, such as
                your email address, legal name, country of residence, zip code,
                your physical address, telephone number(s), applicable payment
                data and information. You agree to be liable to us for any
                inaccurate information, and if your information changes, you
                agree to update it with us as soon as practical.
              </p>
              <br />
              <p>
                <i>
                  <u>Confidentiality and Security: </u>
                </i>
                You are responsible for maintaining the confidentiality of your
                account and password, including but not limited to the
                restriction of access to your computer and/or account. You agree
                to accept responsibility for any and all activities or actions
                that occur under your account and/or password, whether you
                authorized it or not. You must notify us immediately upon
                becoming aware of any breach of security or unauthorized use of
                your account. First Help reserves the right to immediately
                suspend your use of our platform in the event of actual or
                suspected unauthorized use of your account. You agree and
                acknowledge that you have the sole responsibility and liability
                for providing or obtaining, securing (by using appropriate
                virus-detecting software) and for maintaining, any electronic
                device you use to access the Site or Service, such as a
                traditional computer such as a desktop or laptop computer; or a
                mobile device such as a tablet computer or smartphone (an
                “Access Device”) electrical power, telephone and or internet
                services, etc.
              </p>
              <br />
              <p>
                <i>
                  <u>Applicable Laws: </u>
                </i>
                You acknowledge that First Help’s Service and Site may be
                subject to various state and federal laws. You understand and
                agree that both you and First Help intend for the applicable
                laws to apply to the fullest extent possible to validate First
                Help’s ability to conduct business with you by electronic means.
                Disputes over this Site shall be covered by laws in the State
                Massachusetts and disputes over the Arbitration Clause below
                will be decided under the Federal Arbitration Act (“FAA”).
                Further, you acknowledge that this Site is controlled, operated,
                and administered by First Help from its offices within the
                United States. First Help makes no representation that materials
                at this Site are appropriate or available for use at other
                locations outside of the United States. Access to this Site and
                Service from jurisdictions where it is illegal to do so is
                prohibited. You may not use the Site or Service in violation of
                United States export laws and regulations. If you access this
                Site from a location outside of the United States, you are
                responsible for compliance with all local laws.
              </p>
              <br />
              <p>
                <strong>YOU AGREE THAT YOU WILL NOT:</strong>
                <ul>
                  <li>misuse the Site or the Service;</li>
                  <li>
                    attempt to hack, disable, upload or infect our platform
                    and/or Site with viruses or other harmful code;
                  </li>
                  <li>
                    provide or upload intentionally inaccurate, incomplete,
                    fraudulent; deceptive materials, or materials that you do
                    not own, license, or have the right to use;
                  </li>
                  <li>
                    reverse engineer, disassemble, decompile, modify or
                    otherwise attempt to derive source code, architecture,
                    structure, or other elements of our software, Site, or
                    platform; or
                  </li>
                  <li>
                    upload lewd or obscene materials; engage in conduct that is
                    unlawful, harmful, threatening, abusive, tortious,
                    defamatory, libelous, deceptive, fraudulent, invasive of
                    another’s privacy or publicity rights, harassing, profane,
                    or obscene.
                  </li>
                </ul>
              </p>
              <br />
              <p>
                FIRST HELP RESERVES THE RIGHT TO REFUSE SERVICE THROUGH OUT
                SITE, TERMINATE ONLINE ACCOUNTS, OR REMOVE OR EDIT CONTENT, IN
                OUR SOLE DISCRETION.
                <strong>
                  FIRST HELP WILL HAVE THE RIGHT TO TERMINATE YOUR SESSION OR
                  YOUR USE OF THE SERVICE IMMEDIATELY IF YOU BREACH ANY OF THESE
                  TERMS.
                </strong>
              </p>
            </li>
            <br />
            <li>
              <strong>
                <u>INTELLECTUAL PROPERTY </u>
              </strong>
              <p>
                First Help, the First Help logo, and the Site are all property
                of First Help. Other trademarks, product names, and First Help
                names or logos used on this Site are the property of their
                respective owners. All images, graphics, text and other content
                used in connection with the Site are protected by trademark,
                copyright and other proprietary laws and treaty provisions. You
                may not download (except where invited), reproduce, modify,
                publish, distribute, transmit, transfer, sell or modify any
                portion of this Site without our express written authorization.
              </p>
            </li>
            <br />
            <li>
              <strong>
                <u>DISCLAIMER BY FIRST HELP</u>
              </strong>
              <p>
                THE SERVICE AND SITE ARE PROVIDED "AS IS" WITHOUT WARRANTY OF
                ANY KIND. FIRST HELP DOES NOT WARRANT THAT THE SERVICE OR SITE
                WILL BE UNINTERRUPTED OR ERROR FREE; NOR DOES IT MAKE ANY
                WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE
                SERVICE AND/OR SITE. THE SERVICE AND SITE ARE PROVIDED “AS IS”
                AND FIRST HELP DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED,
                INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND
                NON-INFRINGEMENT.
              </p>
              <br />
              <p>
                Further, the Site may contain links to websites controlled or
                offered by third parties. First Help hereby disclaims all
                liability for any other company's website content, products,
                privacy policies, or security. Any representations, advice,
                opinions, services, products, or recommendations provided by the
                linked site providers are those of the providers and not of
                First Help.
              </p>
            </li>
            <br />
            <li>
              <strong>
                <u>FIRST HELP’S LIMITATION OF LIABILITY </u>
              </strong>
              <p>
                TO THE FULLEST EXTENT PERMITTED BY LAW, FIRST HELP AND THIRD
                PARTY BENEFICIARIES SHALL NOT BE LIABLE FOR ANY DAMAGES OF ANY
                KIND, INCLUDING BUT NOT LIMITED TO, DIRECT, INDIRECT, SPECIAL,
                INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES (INCLUDING, WITHOUT
                LIMITATION, ANY LOST PROFITS, BUSINESS INTERRUPTION, LOSS OF
                INFORMATION OR PROGRAMS OR OTHER DATA ON YOUR INFORMATION
                HANDLING SYSTEM) THAT ARISE OUT OF OR ARE RELATED TO THE USE OF
                THIS SITE OR THE ACCESS OF INFORMATION PROVIDED HEREIN, OR THE
                USE OR ACCESS OF, OR THE INABILITY TO USE OR ACCESS, THE
                CONTENT, MATERIALS, AND FUNCTIONS OF THIS SITE, THE SERVICE, OR
                ANY LINKED INTERNET SITE, EVEN IF FIRST HELP AND/OR THIRD PARTY
                BENEFICIARIES IS/ARE EXPRESSLY ADVISED OF, OR REASONABLY COULD
                HAVE FORESEEN, THE POSSIBILITY OF SUCH DAMAGES, HOWEVER THEY
                ARISE, WHETHER IN BREACH OF CONTRACT OR IN TORT (INCLUDING
                NEGLIGENCE). TO THE EXTENT SOME JURISDICTIONS DO NOT ALLOW
                EXCLUSION OR LIMITATION OF LIABILITY FOR DIRECT, INCIDENTAL OR
                CONSEQUENTIAL DAMAGES, THE ABOVE LIMITATION, OR PORTIONS OF IT,
                MAY NOT APPLY TO YOU.
              </p>
            </li>
            <br />
            <li>
              <strong>
                <u>INDEMNIFICATION BY YOU </u>
              </strong>
              <p>
                YOU AGREE, TO THE EXTENT PERMISSIBLE BY LAW, TO BE LIABLE TO
                FIRST HELP FOR AND INDEMNIFY FIRST HELP AGAINST THIRD PARTIES IF
                YOU BREACH ANY OF THESE TERMS.
              </p>
            </li>
            <br />
            <li>
              <strong>
                <u>MISCELLANEOUS TERMS AND CONDITIONS</u>
              </strong>
              <p>
                These Terms shall be governed and construed in accordance with
                the laws of the State of Massachusetts without regard to its
                conflict of law provisions (except as to disputes over the
                Arbitration Clause which will be decided under the FAA). Our
                failure to enforce any right or provision of these Terms will
                not be considered a waiver of those rights. If any provision of
                these Terms is held to be invalid or unenforceable by a court,
                the remaining provisions of these Terms will remain in effect.
                These Terms do not abrogate any other agreements you have
                entered into with First Help, or which have been assigned to
                First Help. Our relationship and the Service remain subject to
                any applicable retail installment sale contract and the
                applicable retail installment sales contract(s) remain in full
                force and effect. To the extent any provision of these Terms
                conflicts with your retail installment sales contract, the terms
                of that contract shall prevail. First Help’s obligations under
                these Terms are subject to existing laws and legal process and
                nothing contained in these Terms is in derogation of First
                Help’s right to comply with law enforcement requests or
                requirements relating to a user's use of the Site, the Service,
                or any information provided to or gathered by First Help. A
                printed version of these Terms and of any notice given in
                electronic form will be admissible in judicial or administrative
                proceedings based upon or relating to the Site to the same
                extent and subject to the same conditions as other business
                documents and records originally generated and maintained in
                printed form.
              </p>
            </li>
            <br />
            <li>
              <strong>
                <u>ARBITRATION CLAUSE / CLASS ACTION WAIVER</u>
              </strong>
              <div className="box">
                <strong>
                  THE FOLLOWING ARBITRATION PROVISIONS SIGNIFICANTLY AFFECT YOUR
                  RIGHTS IN ANY DISPUTE WITH US. PLEASE READ THE FOLLOWING
                  DISCLOSURES AND THE ARBITRATION PROVISION THAT FOLLOWS
                  CAREFULLY BEFORE YOU USE OUR SITE.
                </strong>
                <br />
                <strong>
                  <ol>
                    <li>
                      If either you or we choose, any dispute between you and us
                      will be decided by arbitration and not in court.
                    </li>
                    <li>
                      If such dispute is arbitrated, you and we will give up the
                      right to a trial by a court or a jury trial.
                    </li>
                    <li>
                      You agree to give up any right you may have to bring a
                      class action lawsuit or class arbitration, or to
                      participate in either as a claimant, and you agree to give
                      up any right you may have to consolidate your arbitration
                      with the arbitration of others (hereafter, “Clause 3”).
                    </li>
                    <li>
                      The information that can be obtained in discovery from
                      each other or from third persons in arbitration is
                      generally more limited than in a lawsuit.
                    </li>
                    <li>
                      Other rights that you and/or we would have in court may
                      not be available in arbitration.
                    </li>
                  </ol>
                </strong>
                <br />
                <p>
                  Any claim or dispute, whether in contract, tort, statute, or
                  otherwise (including any dispute over the interpretation,
                  scope, or validity of this provision, arbitration section or
                  the arbitrability of any issue), between you and First Help,
                  or any of our shareholders, employees, agents, contractors,
                  webhosts, web designers, administrators, successors, account
                  servicers, debt buyers, or assigns (each a “Third Party
                  Beneficiary”), which arises out of or relates to accessing our
                  Service on this Site, this Site, mobile application, the data
                  provided to us, the data held by us, alleged improper data
                  exfiltration or sharing, or any resulting transaction or
                  relationship arising out of your access to our Site (including
                  any such relationship with third parties who do not agree to
                  this contract) shall, at the election of either you, us, or a
                  Third Party Beneficiary, be resolved by a neutral, binding
                  arbitration and not by a court action. Notwithstanding the
                  above, this arbitration clause does not require arbitration of
                  a claim or dispute for public injunctive relief under
                  California law and any such claim for a public injunction
                  shall be litigated in Court but only after your individual
                  claim that forms the basis for your representation of the
                  general public is litigated in arbitration under this
                  Agreement. Any claim or dispute is to be arbitrated on an
                  individual basis and not as a class action. The arbitrator may
                  not preside over a consolidated, representative, class,
                  collective, public injunctive relief, or private attorney
                  general action. You expressly waive any right you may have to
                  arbitrate a consolidated, representative, class, collective,
                  public injunctive relief, or private attorney general action.
                  The arbitration shall be administered by the American
                  Arbitration Association under its Consumer Arbitration Rules,
                  or by any other organization that you may choose, subject to
                  our or a Third Party Beneficiary’s approval. If we and a Third
                  Party Beneficiary are parties to an action, then we must both
                  approve the use of an alternative arbitration administration
                  provider. You may get a copy of the rules of the American
                  Arbitration Association (AAA) by visiting its website at
                  <a href="http://www.adr.org">www.adr.org.</a> <br />
                  <br />
                  The arbitrator shall be an attorney or retired judge and shall
                  be selected in accordance with AAA’s applicable rules. The
                  arbitrator shall apply applicable state law and the FAA. The
                  arbitration hearing shall be conducted in the federal district
                  in which you reside. If you demand arbitration first, you will
                  pay the claimant’s initial arbitration filing fees or case
                  management fees required by the applicable rules up to $225,
                  and we will pay any additional initial filing fee or case
                  management fee. We will pay the whole filing fee or case
                  management fee if we demand arbitration first. We will pay the
                  arbitrator’s costs and fees for the first day of arbitration,
                  up to a maximum of eight hours, unless the chosen arbitration
                  provider’s rules require that we pay more. The arbitrator
                  shall decide who shall pay any additional arbitrator’s costs
                  and fees. Nothing in this paragraph shall prevent you from
                  requesting that the applicable arbitration entity reduce or
                  waive your fees, or that we or a Third Party Beneficiary
                  voluntarily pay an additional share of said fees, based upon
                  your financial circumstances or the nature of your claim.
                  <br />
                  <br />
                  You or First Help may still sue in small claims court but only
                  for individualized relief. Both you and First Help have the
                  option to sue the other party in small claims court in lieu of
                  arbitration if the action meets the small claims court’s
                  prerequisites. The small claims court must be in the county
                  where you reside or (for businesses) where your principal
                  place of business is. If you sue in small claims court, you
                  must still only pursue an individual claim and seek individual
                  relief and not on behalf of others or the general public. The
                  waiver of class actions and other representative actions and
                  relief still applies. Neither you nor we waive the right to
                  arbitrate by using self-help remedies, such as repossession,
                  or by filing an action to recover the vehicle, to recover a
                  deficiency balance, or for individual injunctive relief.
                  <br />
                  <br />
                  Your access to our Service, the Site, and providing of data to
                  us in connection with same is a transaction involving
                  interstate commerce. Any arbitration under this agreement
                  shall be governed by the Federal Arbitration Act (9 USC 1, et
                  seq.), and not by any state law concerning arbitration.
                  Judgment upon the award rendered may be entered in any court
                  having jurisdiction. <br />
                  <br />
                  For appeals of arbitration awards, you and First Help agree to
                  utilize AAA’s appellate procedures which are also located at
                  <a href="http://www.adr.org/Rules">www.adr.org/Rules.</a>
                  <br />
                  <br /> If any clause within this arbitration section, other
                  than Clause 3 (above) or any similar provision dealing with
                  class action, class arbitration or consolidation, is found to
                  be illegal or unenforceable, that clause will be severed from
                  this arbitration section, and the remainder of this
                  arbitration section will be given full force and effect. If
                  any part of Clause 3 or any similar provision dealing with
                  class action, class arbitration or consolidation is found to
                  be illegal or unenforceable, then this entire arbitration
                  agreement will be severed and the remaining provisions of this
                  agreement shall be given full force and effect as if the
                  arbitration section of this agreement had not been included in
                  these Terms. In no event shall an arbitrator be authorized to
                  resolve a claim or dispute or make awards or grant relief
                  exceeding the limitations in Clause 3 or any similar provision
                  on class actions, class arbitrations, or consolidation.
                </p>
              </div>
            </li>
            <br />
            <p>Effective Date: 03/18/2024</p>
          </ol>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TermsOfService;
