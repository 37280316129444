import React from "react";

import Footer from "../footer.js";
import Header from "../header.js";
import Contacts from "../contacts.js";
import FHFSocialBondFrameworkSecondPartyOpinion from "../../files/FHFSocialBondFrameworkSecond-PartyOpinion.pdf";
import AssessmentFirstHelpFinancialLLC30Mar23 from "../../files/Assessment - First-Help-Financial-LLC - 30Mar23.pdf";
import ESGSocialBondBrochure2023 from "../../files/First Help Financial ESG Social Bond Brochure 2023 (Revised).pdf";
import SustainanlyticsFirstHelpFinancialPreIssuanceLetter2023 from "../../files/Sustainanlytics First Help Financial Pre-Issuance Letter 2023.pdf";
import SustainanlyticsFirstHelpFinancialPreIssuanceLetterSeptember2023 from "../../files/First Help Financial Pre-Issuance Letter September 2023.pdf";
import {
  arrow,
  downloadIcon,
  investor,
  socialBond,
  socialSPO,
} from "../../images.js";

const Investor = () => {
  return (
    <>
      <div className="investor">
        <Header />
        <div className="investor-container">
          <div className="investor-container__one">
            <div className="investor-container__one__text">
              <h1 className="text-big">Investor Relations</h1>
              <h2 className="text-mid">“First Help” for Our Customers</h2>
              <p className="text-small">
                First Help Financial is an innovative and fast-growing auto
                finance company that is committed to being the "first help" for
                our customers to obtain "financial" services needed to purchase
                vehicles. Our target customer segment is primarily the immigrant
                diaspora from Central and South America who have limited
                residential stability or financial history and have constricted
                access to financial services. We are focused on providing fairly
                priced auto loans to our customers through our proprietary
                underwriting methodology and streamlined operational
                capabilities.
              </p>
            </div>
            <div className="investor-container__one__image">
              <img src={investor} className="access-image" alt="access"></img>
            </div>
          </div>
          <div className="investor-container__two">
            <div className="investor-container__two__image">
              <img src={socialBond} className="access-image" alt="access"></img>
            </div>
            <div className="investor-container__two__text">
              <h1 className="text-big ">Social Bond</h1>
              <h2 className="text-mid ">ESG Frameworks</h2>
              <p className="text-small">
                FHF has published its Social Bond Framework that is aligned with
                Social Bond Principles released by International Capital Markets
                Association (ICMA). ICMA is responsible for releasing guidance
                on ESG frameworks.
              </p>
              <div className="investor-container__two__text__buttons">
                <div className="download-button">
                  <a
                    className="hover-button"
                    href={ESGSocialBondBrochure2023}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download PDF{" "}
                    <img
                      className="download-icon"
                      src={downloadIcon}
                      alt="download"
                    ></img>
                  </a>
                </div>
                <div className="metrics-link">
                  <a href="/social-bond-metrics-2022" target="_blank">
                    {" "}
                    FHF 2022-2 Social Bond Metrics{" "}
                    <img src={arrow} alt="arrow"></img>
                  </a>
                  <a href="/social-bond-metrics-2023" target="_blank">
                    FHF 2023-1 Social Bond Metrics{" "}
                    <img src={arrow} alt="arrow"></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="investor-container__three">
            <div className="investor-container__three__text">
              <h1 className="text-big ">Social SPO Report</h1>
              <h2 className="text-mid">Analytics from Respected NRSRO’s</h2>
              <p className="text-small">
                FHF has obtained a Second Party Opinion (SPO) from Morningstar
                Sustainalytics and Moody's Investors Service on the alignment of
                its Framework with Social Bonds Principal 2021. Morningstar
                Sustainalytics is a global leader in ESG research and data that
                has delivered over 1000 SPOs. Moody's is a Nationally Recognized
                Statistical Rating Organization (NRSRO) and considered one of
                the Big Three rating agencies.
              </p>
              <div className="investor-container__three__text__buttons">
                <div className="download-button">
                  <a
                    className="hover-button"
                    href={FHFSocialBondFrameworkSecondPartyOpinion}
                    target="_blank"
                    rel="noreferrer"
                  >
                    FHF 2022-2 Sustainalytics Report{" "}
                    <img
                      className="download-icon"
                      src={downloadIcon}
                      alt="download"
                    ></img>
                  </a>
                  <a
                    className="hover-button"
                    href={
                      SustainanlyticsFirstHelpFinancialPreIssuanceLetter2023
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    FHF 2023-1 Sustainalytics Report{" "}
                    <img
                      className="download-icon"
                      src={downloadIcon}
                      alt="download"
                    ></img>
                  </a>
                  <a
                    className="hover-button"
                    href={AssessmentFirstHelpFinancialLLC30Mar23}
                    target="_blank"
                    rel="noreferrer"
                  >
                    FHF 2023-1 Moody’s Report{" "}
                    <img
                      className="download-icon"
                      src={downloadIcon}
                      alt="download"
                    ></img>
                  </a>
                  <a
                    className="hover-button"
                    href={
                      SustainanlyticsFirstHelpFinancialPreIssuanceLetterSeptember2023
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    FHF 2023-2 Sustainalytics Report{" "}
                    <img
                      className="download-icon"
                      src={downloadIcon}
                      alt="download"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
            <div className="investor-container__three__image">
              <img src={socialSPO} className="access-image" alt="access"></img>
            </div>
          </div>
        </div>
        <Contacts />
        <Footer />
      </div>
    </>
  );
};

export default Investor;
