import React from "react";

import Footer from "../footer";
import Contacts from "../contacts";
import { logoFull } from "../../images";

const SocialBondMetrics2023 = () => {
  return (
    <>
      <div className="metrics-page-contents">
        <header>
          <a href="/">
            <img src={logoFull} alt="FHF" className="logo-image" />
          </a>
        </header>

        <div className="content">
          <div className="metrics-header">
            <h3>FHF 2023-1 Metrics</h3>
            <p className="font-black">
              Starting with the Subsequent Cutoff Date and annually thereafter,
              FHF will publish certain impact metrics on its Investor page to
              demonstrate the social impact of the Bonds issued. These metrics
              will include:
            </p>
          </div>
          <div className="metrics-table">
            <div className="column-1">
              <h5 className="empty bg-black">
                {}
                <br />
              </h5>
              <p className="bg-dark-blue">1</p>
              <p className="bg-dark-blue">2</p>
              <p className="bg-dark-blue">3</p>
              <p className="bg-blue">4</p>
              <p className="bg-blue">5</p>
              <p className="bg-blue">6</p>
              <p className="bg-light-blue">7</p>
              <p className="bg-light-blue">8</p>
            </div>
            <div className="column-2">
              <h4 className="table-header">SOCIAL BENEFITS</h4>
              <p className="box-size-big">
                Providing Access
                <br />
                to Essential
                <br />
                Services
              </p>
              <p className="box-size-big">
                Enabling Socioeconomics <br />
                Advancement & <br />
                Empowerment
              </p>
              <p className="box-size-medium">
                Entrepreneurship & <br />
                Employment Generation
              </p>
            </div>
            <div className="column-3">
              <h4 className="table-header">IMPACT METRICS</h4>
              <p># of borrowers with Limited English Proficiency (LEP)</p>
              <p># of borrowers with no FICO score at origination</p>
              <p># of borrowers with limited documentation</p>
              <p># of no FICO score borrowers with 700+ FICO</p>
              <p># of no FICO score borrowers with 600-700 FICO</p>
              <p># of no FICO score borrowers with any FICO score</p>
              <p># of self-employed borrowers</p>
              <p># of work trucks or vans financed</p>
            </div>
            <div className="column-4">
              <h4 className="table-header">As of Subsequent Cutoff Date</h4>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
              <p>
                <br />
              </p>
            </div>
          </div>

          <p className="font-black">
            Impact metrics 1-3 (providing access to essential services) and 7-8
            (entrepreneurship and employment generation) would not change
            annually after the allocation has been completed.
          </p>
          <p className="font-black">
            Impact metrics 4-6 (enabling socioeconomic advancement and
            empowerment) will continue to evolve annually. It can be tracked by
            FHF only during the period the loan is active with FHF. After the
            loan is paid off, it will be reported as per its last status when
            active.
          </p>
        </div>
      </div>
      <div className="metrics-footer">
        <Contacts />
        <Footer />
      </div>
    </>
  );
};
export default SocialBondMetrics2023;
