import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Navigation = ({
  setShowPayment,
  activeMenu,
  setActiveMenu,
  setMenuMobile,
  setHamburgerChecked,
}) => {
  let navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <div className="navigation">
        <div
          className={`${
            activeMenu === "/"
              ? "navigation__menu navigation__menu--active "
              : "navigation__menu"
          }`}
        >
          {" "}
          <button
            onClick={() => {
              navigate("/");
              if (setActiveMenu) {
                setActiveMenu("/");
              }
              setShowPayment(false);

              if (setMenuMobile) {
                setHamburgerChecked(false);
                setMenuMobile(false);
              }
            }}
          >
            {t("navigation.home")}
          </button>
        </div>
        <div
          className={`${
            activeMenu === "payments"
              ? "navigation__menu dropdown-active "
              : "navigation__menu"
          }`}
        >
          {" "}
          <button
            onClick={() => {
              if (setActiveMenu) {
                setActiveMenu("payments");
              }
              if (window.innerWidth <= 500) {
                navigate("/payments");
              } else {
                setShowPayment(true);
              }

              if (setMenuMobile) {
                setHamburgerChecked(false);
                setMenuMobile(false);
              }
            }}
          >
            {t("navigation.payments")}
          </button>
        </div>
        <div
          className={`${
            activeMenu === "/customers"
              ? "navigation__menu navigation__menu--active "
              : "navigation__menu"
          }`}
        >
          {" "}
          <button
            onClick={() => {
              navigate("/customers");
              setShowPayment(false);
              if (setActiveMenu) {
                setActiveMenu("/customers");
              }
              if (setMenuMobile) {
                setHamburgerChecked(false);
                setMenuMobile(false);
              }
            }}
          >
            {t("navigation.customers")}
          </button>
        </div>

        <div
          className={`${
            activeMenu === "/dealers"
              ? "navigation__menu navigation__menu--active "
              : "navigation__menu"
          }`}
        >
          {" "}
          <button
            onClick={() => {
              navigate("/dealers");
              setShowPayment(false);
              if (setActiveMenu) {
                setActiveMenu("/dealers");
              }
              if (setMenuMobile) {
                setHamburgerChecked(false);
                setMenuMobile(false);
              }
            }}
          >
            {t("navigation.dealers")}
          </button>
        </div>
        <div
          className={`${
            activeMenu === "/investor-relations"
              ? "navigation__menu navigation__menu--active "
              : "navigation__menu"
          }`}
        >
          {" "}
          <button
            onClick={() => {
              if (setActiveMenu) {
                setActiveMenu("/investor-relations");
              }
              navigate("/investor-relations");
              setShowPayment(false);
              if (setMenuMobile) {
                setHamburgerChecked(false);
                setMenuMobile(false);
              }
            }}
          >
            {t("navigation.investor relations")}
          </button>
        </div>
        <div
          className={`${
            activeMenu === "careers"
              ? "navigation__menu navigation__menu--active "
              : "navigation__menu"
          }`}
        >
          {" "}
          <button
            onClick={() => {
              if (setMenuMobile) {
                setMenuMobile(false);
              }
              window.open(
                "https://apply.workable.com/firsthelpfinancial/",
                "_blank"
              );
            }}
          >
            {t("navigation.careers")}
          </button>
        </div>
      </div>
    </>
  );
};

export default Navigation;
